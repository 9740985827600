<template>
  <div class="">
    <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <!-- LEFT INPUT CONTROLS -->
          <b-col
            lg="9"
            md="9"
            sm="12"
          >
            <b-row>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label-for="input-1">
                  <template>
                    <span>Ngày nhập kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <date-picker
                    placeholder="Chọn ngày nhập kho"
                    class="mb-2 mt-2 form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="$v.form.dateImport.$model"
                    :state="validateState('dateImport')"
                    aria-describedby="input-date-feedback"
                  ></date-picker>

                  <b-form-invalid-feedback
                    v-if="!$v.form.dateImport.required"
                    id="input-date-feedback"
                  >Vui lòng chọn ngày nhập kho</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label-for="input-1">
                  <template>
                    <span>Tổng tiền:</span>
                  </template>
                  <p class="mt-2">{{ convertPrice(totalPrice) }}</p>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-button class='mt-5'>
                  <i
                    class="fa fa-download text-primary cursor-pointer"
                    aria-hidden="true"
                    @click="openFileModal()"
                  >
                  </i>Tệp đính kèm
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group>
                  <template>
                    <span>Kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <Autosuggest
                    class="border-radius-none mt-2"
                    :model="$v.searchStore.$model"
                    :suggestions="filteredOptions"
                    placeholder="kho"
                    :limit="200"
                    @select="onSelectedStore"
                    @change="onInputChange"
                    disabled
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group>
                  <label>Điện thoại: <span class="text-danger">*</span></label>
                  <Autosuggest
                    :model="searchCustomer"
                    :suggestions="filteredOptionsCustomer"
                    placeholder="SĐT Khách hàng"
                    :limit="10"
                    @select="onSelectedCustomer"
                    @change="onInputChangCustomer"
                    suggestionName="phoneNo"
                    disabled
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <div class="d-flex flex-column">
                          <span
                            class="text-primary mb-1"
                            v-if="suggestion.item.phoneNo"
                          >({{ suggestion.item.phoneNo }})</span>
                          <span
                            class="font-weight-bold"
                            v-if="suggestion.item.name"
                          >{{ suggestion.item.name }}</span>
                        </div>
                      </div>
                    </template>
                  </Autosuggest>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group>
                  <label>Họ tên KH: </label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerName"
                    placeholder="Họ tên khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="5">
                <b-form-group>
                  <label>CCCD/CMND:</label>
                  <UpdateCustomerInfo
                    :currentValue="customerIdentifyNo"
                    :customerId="selectedClassCustomer"
                    type="indentifyNo"
                    v-if="isRenderComponent"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="md"
                    type="text"
                    v-model="customerAddress"
                    placeholder="Địa chỉ khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Ghi chú:</label>
                  <b-form-textarea
                    size="sm"
                    v-model="description"
                    :placeholder="'Thêm nội dung ghi chú...'"
                    :rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- RIGHT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
          >
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Tài khoản tiền mặt:</label>
                  <Autosuggest
                    class="border-radius-none mt-2"
                    :model="handleSearchCash"
                    :suggestions="filteredOptionsCash"
                    placeholder="tài khoản tiền mặt"
                    :limit="200"
                    @select="onSelectedCash"
                    @change="onInputChangeCash"
                    :disabled="!filteredOptionsCash.length"
                    suggestionName="suggestionName"
                  />
                  <b-form-invalid-feedback
                    :state="isValidCash"
                    id="input-cash-live-feedback"
                  >Vui lòng chọn tài khoản tiền mặt</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="transferAmount"
                    required
                    placeholder="Nhập số tiền"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <label>Tài khoản chuyển khoản:</label>
                  <Autosuggest
                    :model="searchTransfer"
                    :suggestions="filteredOptionsTransfer"
                    placeholder="tài khoản chuyển khoản"
                    :limit="200"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                    suggestionName="suggestionName"
                  />
                  <b-form-invalid-feedback
                    :state="isValidTransfer"
                    id="input-transfer-live-feedback"
                  >Vui lòng chọn tài khoản chuyển
                    khoản</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <h6>Danh sách sản phẩm:</h6>
        <table class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          ">
          <thead>
            <tr>
              <th
                scope="col"
                class="title-center"
                style="width: 15%"
              >
                Tên sản phẩm
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
              >Tồn</th>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
              >IMEI</th>
              <th
                scope="col"
                class="title-center"
                style="width: 10%"
              >
                Số lượng
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 18%"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 14%"
              >
                Thành tiền
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 13%"
              >
                Chiết khấu
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
              ></th>
            </tr>
          </thead>

          <tbody
            v-for="item in listProductStock"
            :key="item.id"
          >
            <ImportStock
              v-bind:productItem="item"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="true"
            />
          </tbody>
          <tr>
            <td
              :colspan="3"
              class="tdTextAlignPrice"
            >Tổng</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalQuantity) }}
            </td>
            <td></td>
            <td class="tdTextAlignPrice">{{ convertPrice(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalDiscount) }}
            </td>
            <td></td>
          </tr>
        </table>

        <div
          class="col-md-12"
          style="padding-left: 0px"
        >
          <div class="mb-5 mt-5">
            <b-button
              v-show="listProductStock.length > 0"
              v-bind:style="btnCreate"
              class="font-weight-bolder"
              size="sm"
              variant="primary"
              @click="onSubmit"
            >
              Lưu
            </b-button>
          </div>
        </div>
        <b-modal
          ref="file-modal"
          size="lg"
          hide-footer
          title="Chỉnh sửa tệp"
          id="file-modal"
        >
          <b-row>
            <b-col cols="12">
              <UploadFile
                :id="entityUploadId"
                :entity="'evaluation-trade'"
              />
            </b-col>
          </b-row>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import { currencyMask, unMaskPrice, convertPrice } from '@/utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { getToastInstance } from '@/utils/toastHelper';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import UploadFile from '@/view/modules/upload/Upload';
import UpdateCustomerInfo from '@/view/components/customers/UpdateCustomerInfo.vue';
import { makeToastFaile } from '@/utils/common';
import { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      form: {
        dateImport: '',
      },
      isNew: true,
      dateCreate: '',
      listInventories: [],
      listProduct: [],
      description: '',
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      formater: {
        currency: currencyMask,
      },
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      tradeInId: null,
      customerName: '',
      customerAddress: '',
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectedClassCustomer: null,
      isValidCash: true,
      isValidTransfer: true,
      customerIdentityNo: null,
      entityUploadId: null,
      customerIdentifyNo: null,
      isRenderComponent: false,
      idempotenceKey: null,
    };
  },
  validations: {
    form: {
      dateImport: { required },
    },
    searchStore: { required },
  },
  components: {
    UpdateCustomerInfo,
    KTCodePreview,
    ImportStock,
    Autosuggest,
    UploadFile,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.dateCreate = moment().format('HH:mm DD/MM/YYYY');
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu nhập máy cũ', route: '/stocks' },
      { title: 'Tạo phiếu nhập máy cũ' },
    ]);
    this.tradeInId = this.$route.query.id;
    this.entityUploadId = this.tradeInId;
    this.getTradeInById();
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    handleSearchCash() {
      return this.filteredOptionsCash.length > 0
        ? this.searchCash
        : 'Không có thông tin loại tài khoản tiền mặt';
    },
  },
  methods: {
    openFileModal() {
      this.$refs['file-modal'].show();
    },
    convertPrice,
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    isValidCustomerInfo() {
      if (!this.customerIdentityNo) {
        return false;
      }

      return true;
    },
    onSubmit: async function () {
      if (!this.idempotenceKey) {
        this.idempotenceKey = uuidv4();
      }
      this.isNew = false;
      this.isValidCash = this.validateCash();
      this.isValidTransfer = this.validateTransfer();
      this.isValidCustomer = this.isValidCustomerInfo();
      if (!this.isValidCash || !this.isValidTransfer) {
        return makeToastFaile('Vui lòng nhập thông tin thanh toán hợp lệ!');
      }
      // @NOTE: giai đoạn đầu không bắt nhập
      //   if (!this.isValidCustomer) {
      //     return makeToastFaile(
      //       'Vui lòng nhập đầy đủ thông tin khách hàng'
      //     );
      //   }
      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price) * 1,
          discount: unMaskPrice(element.discount) * 1,
        };
      });
      const data = {
        tradeInId: this.tradeInId,
        storeId: this.inventorySelected,
        providerId: this.selectedClassCustomer,
        description: this.description,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
      };
      ApiService.post('stock-slips/import-tradein', data, this.idempotenceKey)
        .then(({ data }) => {
          if (data.status === 1) {
            this.$router.push({
              name: 'list-import-trade-in',
            });
            this.makeToastSuccess(data.message);
          } else {
            makeToastFaile(data.message);
          }
          this.idempotenceKey = uuidv4();
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.isNew = true;
          }
        });
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.options[0].data = this.listInventories.map((element) => {
          return {
            ...element,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptions = [...this.options[0].data];
      });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.inventorySelected !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.inventorySelected,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        this.filteredOptionsCash = [...this.optionsCash[0].data];
        this.filteredOptionsTransfer = [...this.optionsTransfer[0].data];
      });
    },
    getListCustomer() {
      this.optionsCustomer[0].data = [];
      let text = this.searchCustomer;
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          this.optionsCustomer[0].data = customers.map((element) => {
            return {
              id: element.id,
              name: element.fullName,
              phoneNo: element.phoneNo,
            };
          });
          this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
        },
      );
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.onChangeStore();
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [...filteredData];
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedClassCustomer = option.item.id;
      this.getCustomerInfoById();
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer[0].data];
      this.debounceInput();
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [...filteredData];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [...filteredData];
    },
    getAmount(value) {
      return _.toNumber(unMaskPrice(value));
    },
    getTradeInById: function () {
      ApiService.get(`tradeIn/${this.tradeInId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.searchCustomer = data.data.customerPhone;
            this.customerName = data.data.customerName;
            this.selectedClassCustomer = data.data.customerId;
            this.customerIdentifyNo = data.data.customerIdentifyNo;
            this.searchStore = data.data.storeName;
            this.inventorySelected = data.data.storeId;
            this.description = data.data.note;
            const pro = {
              id: this.$uuid.v4(),
              name: data.data.productName,
              totalQuantityInStock: data.data.inStockQuantity,
              IMEI: data.data.imeiCode,
              proId: data.data.productId,
              price: data.data.finalBuyingPrice,
              quantity: 1,
              discount: 0,
              totalPrice: data.data.finalBuyingPrice,
              productType: 2,
            };
            this.listProductStock.push(pro);
            this.isRenderComponent = true;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
          this.fetchAccountants('cash');
          this.fetchAccountants('transfer');
          this.getCustomerInfoById();
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    getCustomerInfoById() {
      ApiService.get(`customer/${this.selectedClassCustomer}`).then((data) => {
        const customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.customerAddress = customerInfo.address;
        this.customerIdentityNo = customerInfo.indentifyNo;
      });
    },
    validateCash() {
      const cashAmount = unMaskPrice(this.cashAmount);
      if (parseInt(cashAmount) > 0 && !this.cashAccountCode) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      const transferAmount = unMaskPrice(this.transferAmount);
      if (parseInt(transferAmount) > 0 && !this.transferAccountCode) {
        return false;
      }
      return true;
    },
  },
};
</script>


<style lang="scss">
.info-import-trade-in {
  .title-center {
    text-align: center;
  }

  .input-group-append {
    cursor: pointer;
    padding-top: 5.5px;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
}
</style>
